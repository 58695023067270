<template>
  <a-card class="card" :bordered="false">
    <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol">
      <a-row>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="名称">
            <a-input v-model="form.customer_name" />
          </a-form-model-item>
        </a-col>
        <a-col v-bind="GLOBAL.queryColSpan">
          <a-form-model-item label="SAP代码">
            <a-input v-model="form.sap_code" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <a-button @click="searchInfo" class="ant-btn-query margin-right10">查询</a-button>
      </a-row>
    </a-form-model>
    <a-table
      rowKey="id"
      size="middle"
      :scroll="{ x: true }"
      :columns="columns"
      :data-source="data"
      :pagination="pagination"
      @change="changeTable"
    >
      <div slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </div>
      <div slot="action" slot-scope="text, record">
        <a @click="updateCode(record)" class="margin-right10">编辑代码</a>
      </div>
    </a-table>
    <a-modal :width="650" v-model="visible" title="编辑SAP代码" @ok="submitCode" @cancel="visible = false">
      <a-form-model
        ref="ruleForm"
        :model="formModel"
        :rules="rules"
        :label-col="labelModel"
        :wrapper-col="wrapperModel"
      >
        <a-form-model-item label="检验重复性">
          <a-select v-model="formModel.checkRepeat" allow-clear>
            <a-select-option value="1">校验</a-select-option>
            <a-select-option value="2">不校验</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="SAP代码" prop="sapCode">
          <a-input v-model="formModel.sapCode" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>
<script>
import { getInvalidCustomer, updateSAPCode } from '@/api/common'
export default {
  data() {
    return {
      labelCol: { span: 4 },
      labelModel: { span: 8 },
      wrapperModel: { span: 12 },
      wrapperCol: { span: 14 },
      formItemLayoutWithOutLabel: {
        wrapperCol: { span: 20, offset: 8 }
      },
      form: {
        customer_name: '',
        sap_code: null
      },
      formModel: {
        id: undefined,
        sapCode: '',
        checkRepeat: '1'
      },
      rules: {
        sapCode: [{ required: true, message: '请输入正确的SAP编码', trigger: 'change', len: 10, whitespace: true }]
      },
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '类型',
          dataIndex: 'type',
          customRender: text => {
            if (text === 1) {
              return '客户'
            } else if (text === 2) {
              return '供应商'
            }
          }
        },
        {
          title: 'SAP代码',
          dataIndex: 'sap_code'
        },
        {
          title: '公司编码',
          dataIndex: 'company_code'
        },
        {
          title: '关联方属性',
          dataIndex: 'link_attr'
        },
        {
          title: '中文名称',
          dataIndex: 'customer_cn_name'
        },
        {
          title: '英文名称',
          dataIndex: 'customer_en_name'
        },
        {
          title: '账户组',
          dataIndex: 'account_group'
        },
        {
          title: '快速搜索项',
          dataIndex: 'search_key'
        },
        {
          title: '街道',
          dataIndex: 'street'
        },
        {
          title: '电话',
          dataIndex: 'phone'
        },
        {
          title: '邮编',
          dataIndex: 'post_code'
        },
        {
          title: '城市',
          dataIndex: 'city'
        },
        {
          title: '国家',
          dataIndex: 'country'
        },
        {
          title: '语言',
          dataIndex: 'language'
        },
        {
          title: '排序码',
          dataIndex: 'sort_code'
        },
        {
          title: '税号',
          dataIndex: 'tax_num'
        },
        {
          title: '操作',
          dataIndex: 'action',
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      data: [],
      visible: false,
      pagination: {
        current: 1,
        pageSize: 10,
        total: 0,
        showTotal: (total, range) => `显示 ${range[0]} ~ ${range[1]} 条记录，共 ${total} 条记录`
      }
    }
  },
  created() {
    this.searchInfo()
  },
  methods: {
    getInfo(param) {
      getInvalidCustomer({
        page: this.pagination.current,
        page_size: this.pagination.pageSize,
        ...param
      }).then(res => {
        this.data = res.list
        this.pagination.total = res.total
      })
    },
    searchInfo() {
      this.pagination.current = 1
      this.pagination.pageSize = 10
      this.getInfo(Object.assign({}, this.form))
    },
    changeTable(pagination) {
      this.pagination = pagination
      this.getInfo(Object.assign({}, this.form))
    },
    updateCode(record) {
      this.visible = true
      this.formModel.id = record.id
    },
    submitCode() {
      this.$refs.ruleForm.validate(res => {
        if (res) {
          updateSAPCode(this.formModel).then(res => {
            if (res) {
              this.$message.success('保存成功!')
              this.getInfo(this.form)
              this.visible = false
              this.formModel.sapCode = ''
            }
          })
        }
      })
    }
  }
}
</script>
<style scoped>
.ant-table td {
  white-space: nowrap;
}
.margin-right10 {
  margin-right: 10px;
}
.ant-form-item {
  margin-bottom: 4px;
}
</style>
